<template>
  <div class="calculation" v-if="text">
    <h3>{{ text.heading }}</h3>
    <table>
        <tr
        v-for="(field, fieldIndex) in fields"
        :key="fieldIndex"
        >
            <td class="field-title">
                {{ text.fields[field.id] }}
                <span class="unit">
                {{ convertUnit(field.unit) }}
                </span>
            </td>
            <td class="field-input">
                <vue-numeric 
                    v-if="field.type === 'amount'" 
                    :type="inputType"
                    v-model.number="field.value" 
                    @input="allFilled"
                    :minus="false"
                />
                <vue-numeric 
                    v-else-if="field.type === 'currency'" 
                    :type="inputType"
                    v-model.number="field.value" 
                    @input="allFilled"
                    :minus="false"
                    :precision="2"
                />
                <label v-else :for="field.id" class="checkbox-label">
                    <input 
                        :id="field.id"
                        :name="field.id"
                        type="checkbox"
                        v-model="field.value"
                        @change="allFilled"
                    >
                    <span class="checkbox-custom rectangular"></span>
                </label>
            </td>
        </tr>
    </table>
    <div class="foot-info">
        <p>* {{ text.footnote[2] }}</p>
    </div>
    <h3>{{ text.heading2 }}</h3>
    <table class="results">
        <tr
        v-for="(field, fieldIndex) in visibleFields"
        :key="fieldIndex"
        :class="field.class"
        >
            <td class="field-title">
                {{ text.results[field.id] }}
                <span class="unit">
                {{ convertUnit(field.unit) }}
                </span>
            </td>
            <td class="results">
                <div>
                {{ field.value | round(field.decimals, field.type, currency) }}
                </div>
            </td>
        </tr>
    </table>
    <div class="foot-info">
        <p>** {{ text.footnote[0] }}<br>
        *** {{ text.footnote[1] }}</p>
        <!-- <pre>{{ current }}</pre> -->
    </div>
  </div>
</template>

<script>
import json from './data'
import VueNumeric from 'vue-numeric'

export default {
    components: {
        VueNumeric
    },
    data () {
        return {
            inputType: 'text',
            json: json,
            text: '',
            selected: 0,
            title: 'Current Operational details',
            fields: [
                {
                    'unit': '%number%',
                    'type': 'amount',
                    'value': 0,
                    'id': 'trucks_in_fleet',
                    'step': 10
                },
                {
                    'unit': '%distance%',
                    'type': 'amount',
                    'value': 0,
                    'id': 'avg_annual_mileage',
                    'step': 10000
                },
                {
                    'unit': '%distance%',
                    'type': 'amount',
                    'value': 0,
                    'id': 'current_drain_intervals',
                    'step': 10000
                },
                {
                    'unit': '%currency% / %liquid%',
                    'type': 'currency',
                    'value': 0,
                    'id': 'avg_fuel_cost',
                    'step': 1
                },
                {
                    'unit': '%currency% / %liquid%',
                    'type': 'currency',
                    'value': 0,
                    'id': 'avg_engine_oil_cost',
                    'step': 1
                },
                {
                    'unit': '',
                    'type': 'checkbox',
                    'value': false,
                    'id': 'using_petrona_fuel_economy'
                }
            ],
            results: [
                {
                    'unit': '%liquid% / %vehicle%',
                    'type': 'amount',
                    'value': 0,
                    'id': 'saved_fuel',
                    'decimals': 0,
                    'show': true
                },
                {
                    'unit': '%currency% / %vehicle%',
                    'type': 'currency',
                    'value': 0,
                    'id': 'saved_cost_of_fuel',
                    'decimals': 2,
                    'show': true,
                    'class': 'green-line'
                },
                {
                    'unit': '%distance%',
                    'type': 'amount',
                    'value': 0,
                    'id': 'increased_mileage',
                    'decimals': 0,
                    'show': false
                },
                {
                    'unit': '%currency%',
                    'type': 'currency',
                    'value': 0,
                    'id': 'total_cost_of_mileage',
                    'decimals': 2,
                    'show': false
                },
                {
                    'unit': '%currency% / %fleet%',
                    'type': 'currency',
                    'value': 0,
                    'id': 'total_saving',
                    'decimals': 2,
                    'show': true,
                    'class': 'green-back green-line'
                },
                {
                    'unit': '%weight% / %fleet%',
                    'type': 'amount',
                    'value': 0,
                    'id': 'carbon_dioxide_saving',
                    'decimals': 0,
                    'show': true,
                    'class': 'green-back green-line'
                },
                {
                    'unit': '%liquid% / %fleet%',
                    'type': 'amount',
                    'value': 0,
                    'id': 'sustainability_savings',
                    'decimals': 0,
                    'show': true,
                    'class': 'green-back'
                }
            ],
            number: 'No',
            currency: 'GBP',
            distanceUnits: 'Km',
            weightUnits: 'Kg',
            language: 'en',
            current: {
                avg_fuel_consumption: 3,
                avg_sump_size: 20,
                vehicle_availability_value: 250,
                labour_cost_per_hour: 80,
                our_of_service_downtime: 4,
                fuel_economy_saving_estimate: 1.3,
                drain_interval_estimate: 150000,
                oil_price: 8
            },
            regional: {
                EUR: {
                    vehicle_availability_value: 250,
                    labour_cost_per_hour: 80,
                    oil_price: 8
                },
                GBP: {
                    vehicle_availability_value: 210,
                    labour_cost_per_hour: 70,
                    oil_price: 7
                },
                PLN: {
                    vehicle_availability_value: 1100,
                    labour_cost_per_hour: 350,
                    oil_price: 35
                },
                TRY: {
                    vehicle_availability_value: 2500,
                    labour_cost_per_hour: 800,
                    oil_price: 80
                }
            }
        }
    },
    mounted() {
        this.setRegionalValues()
    },
    computed: {
        visibleFields: function() {
            return this.results.filter(function(r) {
                return r.show
            })
        }
    },
    methods: {
        setRegionalValues () {
            if (this.$route.query.language) this.language = this.$route.query.language
            if (this.$route.query.currency) this.currency = this.$route.query.currency
            if (this.currency === 'GBP') {
                this.distanceUnits = 'Miles'
                this.current.avg_fuel_consumption = 1.86
                this.current.drain_interval_estimate = 93206
            }
            if (this.regional[this.currency]) this.current.vehicle_availability_value = this.regional[this.currency].vehicle_availability_value
            if (this.regional[this.currency]) this.current.labour_cost_per_hour = this.regional[this.currency].labour_cost_per_hour
            if (this.regional[this.currency]) this.current.oil_price = this.regional[this.currency].oil_price
            this.text = this.json[this.language] || this.json['en']
        },
        convertUnit (unit) {
            if (unit) {
                let formatted = unit.replace('%currency%', this.currency)
                formatted = formatted.replace('%distance%', this.distanceUnits)
                formatted = formatted.replace('%liquid%', this.text.variables.litres)
                formatted = formatted.replace('%weight%', this.weightUnits)
                formatted = formatted.replace('%fleet%', this.text.variables.fleet)
                formatted = formatted.replace('%vehicle%', this.text.variables.vehicle)
                formatted = formatted.replace('%number%', this.text.variables.number)
                return formatted
            }
        },
        allFilled () {
            console.log('allFilled')
            if (
                this.fields[0].value > 0 &&
                this.fields[1].value > 0 &&
                this.fields[2].value > 0 &&
                this.fields[3].value > 0 &&
                this.fields[4].value > 0
            ) {
                this.runCalculations()
            }
        },
        swapNegativeAndPositive (value) {
            let swapped = value *= -1
            return swapped
        },
        runCalculations () {
            console.log('runCalculations')
            this.calcSavedFuel()
            this.calcSavedCostOfFuel()
            this.calcIncreasedMilage()
            this.calcTotalCostOfMileage()
            this.calcTotalSaving()
            this.calcCO2Saving()
            this.calcSustainabilitySavings()
        },
        calcSavedFuel () {
            const avgVehicleUtilization = this.fields[1].value
            const avgFuelConsumption = this.current.avg_fuel_consumption
            const fuelEconomySavings = this.current.fuel_economy_saving_estimate / 100
            const fuelSavings = 1 + fuelEconomySavings
            const fuelConsumptionSaving = fuelSavings * avgFuelConsumption
            const dividedValue = avgVehicleUtilization / avgFuelConsumption
            const notUsing = avgVehicleUtilization / fuelConsumptionSaving
            const using = avgVehicleUtilization / (fuelConsumptionSaving * 1.005)
            this.results[0].value = this.fields[5].value ? dividedValue - using : dividedValue - notUsing
        },
        calcSavedCostOfFuel () {
            const savedFuel = this.results[0].value
            const avgFuelCost = this.fields[3].value
            this.results[1].value = savedFuel * avgFuelCost
        },
        calcIncreasedMilage () {
            this.results[2].value = this.current.drain_interval_estimate - this.fields[2].value
        },
        calcTotalCostOfMileage () {
            const avgVehicleUtilization = this.fields[1].value
            const drainOilEstimate = this.current.drain_interval_estimate
            const vehicleAvailability = this.current.vehicle_availability_value
            const laborCostPerHour = this.current.labour_cost_per_hour
            const outOfServiceDowntime = this.current.our_of_service_downtime
            const petronasOilPrice = this.current.oil_price
            const avgSumpSize = this.current.avg_sump_size
            const currentDrainInterval = this.fields[2].value
            const avgEngineOilCost = this.fields[4].value
            const totalCostofMileage = ((avgVehicleUtilization / drainOilEstimate) * (((vehicleAvailability + laborCostPerHour) * outOfServiceDowntime) + (petronasOilPrice * avgSumpSize))) - ((avgVehicleUtilization / currentDrainInterval) * (((vehicleAvailability + laborCostPerHour) * outOfServiceDowntime) + (avgEngineOilCost * avgSumpSize)))
            this.results[3].value = this.swapNegativeAndPositive(totalCostofMileage)
        },
        calcTotalSaving () {
            const numberOfTrucks = this.fields[0].value
            const savedCostOfFuel = this.results[1].value
            const totalCostofMileage = this.results[3].value
            this.results[4].value = (totalCostofMileage + savedCostOfFuel) * numberOfTrucks
        },
        calcCO2Saving () {
            const savedFuel = this.results[0].value
            const numberOfTrucks = this.fields[0].value
            this.results[5].value = savedFuel * 2.68 * numberOfTrucks
        },
        calcSustainabilitySavings () {
            const numberOfTrucks = this.fields[0].value
            const avgSumpSize = this.current.avg_sump_size
            const drainOilEstimate = this.current.drain_interval_estimate
            const currentDrainInterval = this.fields[2].value
            const truckBySump = numberOfTrucks * avgSumpSize
            this.results[6].value = (truckBySump * drainOilEstimate / currentDrainInterval) - truckBySump
        }
    },
    filters: {
        round: function (value, decimals, type, currency) {
            return type !== 'currency' ? value.toLocaleString(undefined, {maximumFractionDigits:decimals}) : value.toLocaleString(undefined, { style: 'currency', currency: currency });
        }
    }
}
</script>

<style>
.calculation {
    padding: 10px;
}
table {
    width: 100%;
    border-collapse: collapse;
}
tr {
    border-bottom: 1px solid #eee;
}
td {
    padding: 8px 20px;
}
td.field-title {
    text-align: left;
    width: 70%;
}
td.field-input {
    background-color: #dcdcdc;
}
td.field-input:hover {
    background-color: #bfbfbf;
}
table.results tr.green-line {
    border-bottom: 1px solid #00a19c;
}
table.results tr.green-back {
    background-color: #d5f4f3;
}
table.results tr.green-back td.field-title {
    font-weight: bold;
}
table.results tr:last-of-type {
    border-bottom: 4px solid #00a19c;
}
td.results {
    font-weight: bold;
}
table.results tr:hover {
    background-color: rgba(0, 0, 0, 0.05);
}
table.results tr.green-back:hover {
    background-color: rgba(51,212,197,.4);
}
input {
    border: none;
    border-radius: 0;
    background-color: transparent;
    width: 100%;
    text-align: center;
    font-weight: bold;
    font-size: inherit;
    font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
}
input[type="text"]:focus {
    outline: none;

}
span.unit {
    float: right;
    color: #444;
    font-weight: normal;
}
h3 {
    text-align: left;
    margin: 20px 0 0;
    padding: 10px 20px;
    border-bottom: 4px solid #00a19c;
}
.foot-info {
    text-align: left;
    color: #777;
    font-size: 80%;
    padding-left: 20px;
}
.checkbox-label {
    display: block;
    position: relative;
    margin: auto;
    cursor: pointer;
    font-size: 18px;
    line-height: 20px;
    height: 20px;
    width: 20px;
    clear: both;
}
.checkbox-label input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}
.checkbox-label .checkbox-custom {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: transparent;
    border-radius: 3px;
    transition: all .3s ease-out;
    border: 2px solid #00a19c;
}
.checkbox-label .checkbox-custom:before {
    position: absolute;
    content: "";
    left: 10px;
    top: 10px;
    width: 0;
    height: 0;
    border-radius: 5px;
    border: 2px solid #fff;
    transform: scale(0);
}
.checkbox-label .checkbox-custom:after {
    position: absolute;
    content: "";
    left: 10px;
    top: 10px;
    height: 0;
    width: 0;
    border-radius: 3px;
    border: solid #00a19c;
    border-width: 0 3px 3px 0;
    transform: rotate(0deg) scale(0);
    opacity: 1;
    transition: all .3s ease-out;
}
.checkbox-label input:checked~.checkbox-custom {
    background-color: #fff;
    border-radius: 3px;
    transform: rotate(0deg) scale(1);
    opacity: 1;
    border: 2px solid #00a19c;
}
.checkbox-label input:checked~.checkbox-custom:before {
    left: -3px;
    top: -3px;
    width: 24px;
    height: 24px;
    border-radius: 5px;
    transform: scale(3);
    opacity: 0;
    z-index: 999;
    transition: all .3s ease-out;
}
.checkbox-label input:checked~.checkbox-custom:after {
    transform: rotate(45deg) scale(1);
    opacity: 1;
    left: 7px;
    top: 2px;
    width: 5px;
    height: 11px;
    border: solid #00a19c;
    border-width: 0 2px 2px 0;
    background-color: transparent;
    border-radius: 0;
}
</style>