export default {
    en: {
        heading: 'Current operational details',
        heading2: 'Results',
        fields: {
            trucks_in_fleet: 'Number of trucks in fleet',
            avg_annual_mileage: 'Average annual mileage *',
            current_drain_intervals: 'Current drain interval',
            avg_fuel_cost: 'Average fuel cost',
            avg_engine_oil_cost: 'Average engine oil cost',
            using_petrona_fuel_economy: 'Use of PETRONAS Transmission Fluid (with Fuel Economy benefit)'
        },
        results: {
            saved_fuel: 'Saved fuel **',
            saved_cost_of_fuel: 'Saved cost of fuel **',
            increased_mileage: 'Increased mileage **',
            total_cost_of_mileage: 'Total Cost of maintenance **',
            total_saving: 'Total saving **',
            carbon_dioxide_saving: 'Saving of CO₂ per year ***',
            sustainability_savings: 'Sustainability index (amount of engine oil saved) **'
        },
        footnote: [
            'Per Year',
            'Average 2.68kg of CO₂ per litre of diesel',
            'Per vehicle'
        ],
        variables: {
            fleet: 'Fleet',
            vehicle: 'Vehicle',
            litres: 'Litres',
            number: 'No'
        }
    },
    pl: {
        heading: 'Obecne szczegóły operacyjne',
        heading2: 'Wyniki',
        fields: {
            trucks_in_fleet: 'Liczba pojazdów ciężarowych we flocie',
            avg_annual_mileage: 'Średni roczny przebieg samochodu *',
            current_drain_intervals: 'Obecny okres pomiędzy wymianami oleju',
            avg_fuel_cost: 'Średni koszt paliwa',
            avg_engine_oil_cost: 'Średni koszt oleju silnikowego',
            using_petrona_fuel_economy: 'Wykorzystanie oleju przekładniowego PETRONAS (z korzyścią dla zużycia paliwa)'
        },
        results: {
            saved_fuel: 'Zaoszczędzone paliwo **',
            saved_cost_of_fuel: 'Zaoszczędzony koszt paliwa **',
            increased_mileage: 'Increased mileage **',
            total_cost_of_mileage: 'Total Cost of maintenance **',
            total_saving: 'Oszczędności razem **',
            carbon_dioxide_saving: 'Oszczędność emisji CO₂ na rok ***',
            sustainability_savings: 'Indeks zrównoważonego rozwoju (ilość zaoszczędzonego oleju silnikowego) **'
        },
        footnote: [
            'Na rok',
            'Średnio 2,68 kg CO₂ na litr paliwa diesel',
            'Na pojazd'
        ],
        variables: {
            fleet: 'Flota',
            vehicle: 'Pojazd',
            litres: 'Litry',
            number: 'Nr'
        }
    },
    tr: {
        heading: 'Mevcut operasyonel bilgiler',
        heading2: 'Sonuçlar',
        fields: {
            trucks_in_fleet: 'Filodaki Kamyon Sayısı',
            avg_annual_mileage: 'Ortalama yıllık kilometre *',
            current_drain_intervals: 'Mevcut Yağ Değişim Aralığı',
            avg_fuel_cost: 'Ortalama Yakıt Maliyeti ',
            avg_engine_oil_cost: 'Ortalama Motor Yağı Maliyeti',
            using_petrona_fuel_economy: 'PETRONAS Şanzıman Yağı Kullanımı (Yakıt Ekonomisi özellikli)'
        },
        results: {
            saved_fuel: 'Yakıt tasarrufu **',
            saved_cost_of_fuel: 'Yakıt maliyeti tasarrufu **',
            increased_mileage: 'Increased mileage **',
            total_cost_of_mileage: 'Total Cost of maintenance **',
            total_saving: 'Toplam tasarruf **',
            carbon_dioxide_saving: 'Yıllık tasarruf edilen CO₂ emisyonu ***',
            sustainability_savings: 'Sürdürülebilirlik indeksi (tasarruf edilen motor yağı miktarı) **'
        },
        footnote: [
            'Yıllık',
            'Ortalama bir litre dizel başına 2,68 kg CO₂',
            'Araç başına'
        ],
        variables: {
            fleet: 'Filo',
            vehicle: 'Araç',
            litres: 'Litre',
            number: 'No'
        }
    },
    es: {
        heading: 'Detalles operativos actuales',
        heading2: 'Resultados',
        fields: {
            trucks_in_fleet: 'Número de camiones en la flota',
            avg_annual_mileage: 'Kilometraje promedio anual *',
            current_drain_intervals: 'Intervalo actual entre cambios de aceite ',
            avg_fuel_cost: 'Coste promedio del combustible',
            avg_engine_oil_cost: 'Coste promedio del aceite de motor',
            using_petrona_fuel_economy: 'Uso del Fluido de Transmisión PETRONAS (con mejora del ahorro de combustible)'
        },
        results: {
            saved_fuel: 'Combustible ahorrado **',
            saved_cost_of_fuel: 'Ahorro en costes de combustible **',
            increased_mileage: 'Increased mileage **',
            total_cost_of_mileage: 'Total Cost of maintenance **',
            total_saving: 'Ahorro total **',
            carbon_dioxide_saving: 'Ahorro de CO₂ por año ***',
            sustainability_savings: 'Índice de sostenibilidad (cantidad de aceite de motor ahorrado) **'
        },
        footnote: [
            'Por año',
            'Promedio. 2,68 kg de CO₂ por litro de diésel',
            'Por vehículo'
        ],
        variables: {
            fleet: 'Flota',
            vehicle: 'Vehículo',
            litres: 'Litros',
            number: 'Núm.'
        }
    },
    it: {
        heading: 'Dettagli operativi correnti',
        heading2: 'Risultati',
        fields: {
            trucks_in_fleet: 'Numero di camion nella flotta',
            avg_annual_mileage: 'Chilometraggio medio annuo *',
            current_drain_intervals: 'Intervallo attuale del cambio olio',
            avg_fuel_cost: 'Costo medio del carburante',
            avg_engine_oil_cost: 'Costo medio dell\'olio motore',
            using_petrona_fuel_economy: 'Uso di fluido per trasmissioni PETRONAS (con benefici per il risparmio di carburante)'
        },
        results: {
            saved_fuel: 'Carburante risparmiato **',
            saved_cost_of_fuel: 'Costo del carburante risparmiato **',
            increased_mileage: 'Increased mileage **',
            total_cost_of_mileage: 'Total Cost of maintenance **',
            total_saving: 'Risparmio totale **',
            carbon_dioxide_saving: 'Risparmio di CO₂ all\'anno ***',
            sustainability_savings: 'Indice di sostenibilità (quantità di olio motore risparmiato) **'
        },
        footnote: [
            'All\'anno',
            'In media 2,68 kg di CO₂ per ogni litro di gasolio',
            'Per veicolo'
        ],
        variables: {
            fleet: 'Flotta',
            vehicle: 'Veicolo',
            litres: 'Litri',
            number: 'No'
        }
    }
  }
  